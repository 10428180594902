import { createSlice } from "@reduxjs/toolkit";
import { LS_AUTH_TOKEN } from "../../../Constants";

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    setAuth(state, action) {
      return action.payload;
    },
    resetAuth() {
      localStorage.removeItem(LS_AUTH_TOKEN);
      return {};
    },
  },
});

const { actions, reducer } = authSlice;

export const { setAuth, resetAuth } = actions;

export default reducer;
