import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addToCart(state: any, action: any) {
      const isItemInCart = state.find(
        (item: any) => item.id === action.payload.id
      );

      if (isItemInCart) {
        return state.map((item: any) =>
          item.id === action.payload.id
            ? { ...item, amount: item.amount + 1 }
            : item
        );
      }

      return [...state, { ...action.payload, amount: 1 }];
    },
    changeInCart(state, action) {
      return [];
    },
    deleteFromCart(state: any, action: any) {
      return state.reduce((acc: any, item: any) => {
        if (item.id === action.payload.id) {
          if (item.amount === 1) return acc;
          return [...acc, { ...item, amount: item.amount - 1 }];
        } else {
          return [...acc, item];
        }
      }, []);
    },
    resetCart() {
      return [];
    },
  },
});

const { actions, reducer } = cartSlice;

export const { addToCart, resetCart, changeInCart, deleteFromCart } = actions;

export default reducer;
