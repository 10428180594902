import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./slices/rootReducer";

export default function configureAppStore(preloadedState: any) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  store.subscribe(() => {
    localStorage.setItem("reduxState", JSON.stringify(store.getState()));
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./slices/rootReducer", () =>
      store.replaceReducer(rootReducer)
    );
  }

  return store;
}
