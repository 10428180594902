import { FC } from "react";
import {
  Avatar,
  Container,
  IconButton,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { wrapSX } from "./styles";
import { Telegram } from "@mui/icons-material";

const styleBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const ContactBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <Container sx={wrapSX}>
      <Box sx={styleBox}>
        <Typography
          sx={{
            mb: "70px",
            fontSize: "38px",
            lineHeight: "1.25",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {t("contact_us")}
        </Typography>
        <Avatar
          //alt="Remy Sharp"
          //src="https://mui.com/static/images/cards/contemplative-reptile.jpg"
          sx={{
            width: 180,
            height: 180,
            mb: "20px",
            background: "white",
            fontSize: "60px",
            color: "black",
            boxShadow: "0 0 5px 10px #00000020",
          }}
        >
          W
        </Avatar>
        <Typography sx={{ mb: "45px", fontSize: "18px", fontWeight: 600 }}>
          {t("winterx")}
        </Typography>
        <Paper
          sx={{
            mb: "45px",
            boxShadow: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: "10px" }}>{t("e-mail")}:</Typography>
          <Button
            sx={{
              "&:hover": { color: "blue" },
              textTransform: "initial",
              textDecoration: "none",
              color: "inherit",
            }}
            component="a"
            href="mailto:winterx.team@yandex.ru?subject=Выражаю заинтересованность"
          >
            <Typography>{t("winterx.team@yandex.ru")}</Typography>
          </Button>
        </Paper>
        <Paper sx={{ boxShadow: "none" }}>
          <IconButton
            href="https://t.me/winterx_project"
            aria-label="telegram"
            color="primary"
          >
            <Telegram />
          </IconButton>
        </Paper>
      </Box>
    </Container>
  );
};

export default ContactBlock;
