import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {},
  reducers: {
    setProfile(state, action) {
      return action.payload;
    },
    resetProfile() {
      return {};
    },
  },
});

const { actions, reducer } = profileSlice;

export const { setProfile, resetProfile } = actions;

export default reducer;
