import {
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
  Tooltip,
  Container,
  Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { EMetaFields } from "./enums";
import { useRef } from "react";

export const RequiredForSystemBlock = ({ setField, meta, metaErrors }: any) => {
  const cdekRef = useRef(null);
  const contactRef = useRef(null);
  const fullNameRef = useRef(null);
  const phoneRef = useRef(null);
  const commentRef = useRef(null);

  return (
    <FormControl>
      <Container
        sx={{ display: "flex", flexDirection: "column", mb: "10px" }}
        disableGutters
      >
        <Link
          sx={{ alignSelf: "flex-end", mb: "5px", fontSize: "14px" }}
          href="https://www.cdek.ru/ru/offices/"
          target="_blank"
        >
          Выбрать офис CDEK
        </Link>
        <Tooltip title="Укажите удобный пункт выдачи">
          <TextField
            inputRef={cdekRef}
            sx={{ width: "100%" }}
            onChange={(e) => setField(EMetaFields.cdek, e.target.value)}
            label="CDEK"
            error={!!metaErrors?.cdek}
            helperText={metaErrors?.cdek}
            size="small"
            required
          />
        </Tooltip>
      </Container>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          onChange={() => setField(EMetaFields.isAnonymous, !meta?.isAnonymous)}
          control={<Checkbox />}
          label="Отправить анонимно"
        />
        <Tooltip title="Потребуется указать контакты (телеграм, email и тд), куда будет отправлен код получения">
        <InfoIcon style={{ fontSize: "20px" }} color="action" />
        </Tooltip>
      </div> */}
      {/* {meta.isAnonymous ? ( */}
      <Tooltip title="Вимание! На данную почту будет отправлен код получения посылки, поэтому убедитесь в наличии доступа к почте">
        <TextField
          // inputRef={contactRef}
          value={meta?.contact}
          sx={{ width: "100%", marginBottom: "10px" }}
          onChange={(e) => setField(EMetaFields.contact, e.target.value)}
          label="Контакт"
          // error={!!metaErrors?.contact}
          // helperText={metaErrors?.contact}
          size="small"
          // required
          disabled
        />
      </Tooltip>
      {/* ) : (
        <>
          <TextField
            inputRef={fullNameRef}
            sx={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => setField(EMetaFields.fullName, e.target.value)}
            label="ФИО"
            error={!!metaErrors?.fullName}
            helperText={metaErrors?.fullName}
            size="small"
            required
          />
          <TextField
            inputRef={phoneRef}
            sx={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => setField(EMetaFields.phone, e.target.value)}
            label="Телефон"
            error={!!metaErrors?.phone}
            helperText={metaErrors?.phone}
            size="small"
            required
          />
        </>
      )} */}
      <TextField
        inputRef={commentRef}
        sx={{ width: "100%", marginBottom: "30px" }}
        onChange={(e) =>
          e?.target?.value?.length <= 200 &&
          setField(EMetaFields.comment, e.target.value)
        }
        label="Комментарий"
        placeholder="Комментарий (макс. 200 символов)"
        error={!!metaErrors?.comment}
        helperText={metaErrors?.comment}
        size="small"
        multiline
        maxRows={4}
        minRows={4}
      />
    </FormControl>
  );
};
