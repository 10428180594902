import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
  name: "products",
  initialState: [],
  reducers: {
    setProducts(state, action) {
      return action.payload;
    },
    resetProducts() {
      return [];
    },
  },
});

const { actions, reducer } = productsSlice;

export const { setProducts, resetProducts } = actions;

export default reducer;
