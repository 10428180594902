export enum EServerErrors {
  ZERO,
  FIRST,
  SECOND,
  THIRD,
  FOUR,
  FIVE,
  SIX,
  SEVEN,
  TOKEN_EXPIRED,
  TOKEN_IS_INVALID,
}
