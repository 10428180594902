import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../Services/slices/Products";
import { useFetch } from "./useFetch";

export const useProducts = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { getRequest } = useFetch({ url: "/api/products" });
  const productList = useSelector((state: any) => state?.products);

  const getProductById = useCallback(
    (productId: number) => {
      getRequest({ additionalUrl: "/" + productId });
    },
    [getRequest]
  );

  useEffect(() => {
    setIsLoading(true);
    getRequest()
      .then(({ data }) => dispatch(setProducts(data)))
      .finally(setIsLoading.bind(null, false));
  }, []);

  return { isLoading, productList, getProductById };
};
