import { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const containerSx = {
  pt: "60px",
  pb: "30px",
  backgroundColor: "#0d0d0d",
};
const linkStyle = {
  fontSize: "13px",
  color: "#ccc",
  transition: "0.3s ease-out",
  textDecoration: "none",
};
const listItemStyle = {
  paddingLeft: "8px",
  "a:hover": {
    color: "#fff !important",
  },
};

const Footer: FC = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container
      sx={containerSx}
      maxWidth={false}
      component="footer"
      disableGutters
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid width={!isSM ? "100%" : "initial"} textAlign="center">
          <Typography
            color="#ccc"
            marginRight={!isSM ? 0 : "15px"}
            marginBottom={!isSM ? "20px" : 0}
          >
            Наша цель - быть лидерами в интерактиве
          </Typography>
        </Grid>
        <Grid>
          <Container disableGutters>
            <Container disableGutters>
              <List disablePadding>
                <ListItem disablePadding>
                  <Typography
                    fontSize="14px"
                    pb="5px"
                    fontWeight="bolder"
                    color="#ccc"
                  >
                    Документы:
                  </Typography>
                </ListItem>
                <ListItem
                  sx={
                    isSM ? listItemStyle : { ...listItemStyle, paddingLeft: 0 }
                  }
                  disablePadding
                >
                  <Link style={linkStyle} to="/docs/terms">
                    Пользовательское соглашение
                  </Link>
                </ListItem>
                <ListItem
                  sx={
                    isSM ? listItemStyle : { ...listItemStyle, paddingLeft: 0 }
                  }
                  disablePadding
                >
                  <Link style={linkStyle} to="/docs/privacy">
                    Политика конфиденциальности
                  </Link>
                </ListItem>
                <ListItem
                  sx={
                    isSM ? listItemStyle : { ...listItemStyle, paddingLeft: 0 }
                  }
                  disablePadding
                >
                  <Link style={linkStyle} to="/docs/cookies">
                    Политика использования cookies
                  </Link>
                </ListItem>
                <ListItem
                  sx={
                    isSM ? listItemStyle : { ...listItemStyle, paddingLeft: 0 }
                  }
                  disablePadding
                >
                  <Link style={linkStyle} to="/docs/app_license">
                    Лицензия приложения Winterx
                  </Link>
                </ListItem>
              </List>
            </Container>
          </Container>
        </Grid>
        <Grid
          container
          mt="25px"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Grid minWidth="100%">
            <Container
              sx={{
                display: "flex",
                justifyContent: "center",
                pb: "5px",
                transition: "0.3s ease-out",
                "a:hover": { color: "#fff !important" },
              }}
              maxWidth={false}
              disableGutters
            >
              <Typography fontSize="14px" mr="5px" color="#ccc">
                Написать нам:{" "}
              </Typography>
              <Link style={linkStyle} to="mailto:winterx.team@yandex.ru">
                winterx.team@yandex.ru
              </Link>
            </Container>
          </Grid>
          <Grid>
            <Typography color="#ccc" variant="caption">
              192007, Санкт-Петербург, Лиговский пр-кт, 164, стр.А, ящик 47
            </Typography>
            <Typography
              maxWidth="500px"
              fontSize="12px"
              textAlign="center"
              color="#ccc"
            >
              Продукция является прототипом, может иметь незначительные
              потертости, сколы и прочие дефекты, которые не влияют на
              функционал продукции, и не считаются браком производителя. Также
              производитель не несет ответственности за поломки, возникшие в
              результате доставки товара до покупателя.
            </Typography>
            <Typography variant="caption" color="#ccc">
              {"© " + t("winterx") + " " + new Date().getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
