import { FC, useState, MouseEvent, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { mapEnumPageToPath } from "../../mappers/mapEnumPageToPath";
import { EPage } from "../../../Defenitions/enums/EPage";
import Fade from "@mui/material/Fade";
import { EAppModal } from "../../../Defenitions/enums/EAppModal";
import { useAppModal } from "../../../CustomHooks/useAppModal";
import { useFirebase } from "../../../CustomHooks/useFirebase";

const pages = [
  // EPage.AUTH,
  // EPage.SHOP,
];
const settings = [EPage.PROFILE];
const enumToMenuMapper: any = {
  [EPage.PROFILE]: "Профиль",
};

const menuItemSx = { justifyContent: "center", padding: 0 };
const menuItemTitleSx = { display: "block", padding: "8px 15px" };

const Header: FC<{ isDisplay: boolean }> = ({ isDisplay }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setAppModal } = useAppModal();
  const { logout } = useFirebase();

  const appBarRef = useRef<any>(null);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { authData } = useSelector((state: any) => {
    const authData = state.auth;

    return { authData };
  });

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoTitle = useMemo(() => t("winterx"), [t]);

  return (
    <>
      {isDisplay && (
        <div
          style={{
            width: "100%",
            paddingTop: appBarRef?.current?.offsetHeight,
          }}
        />
      )}
      <Fade in={isDisplay}>
        <AppBar ref={appBarRef} position="fixed" color="default">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component={Link}
                to={mapEnumPageToPath[EPage.HOME]}
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "Roboto",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {logoTitle}
              </Typography>

              <Box sx={{ flexGrow: 1, flexBasis: "40px" }} />
              {/*
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              */}
              <Typography
                variant="h5"
                noWrap
                component={Link}
                to=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "Roboto",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                {logoTitle}
              </Typography>
              {/* <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    <Link to={mapEnumPageToPath[page]}>{page}</Link>
                  </Button>
                ))}
              </Box> */}

              <Box sx={{ flexGrow: 0 }}>
                {!authData?.email ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={setAppModal.bind(null, EAppModal.AUTH)}
                  >
                    Войти
                  </Button>
                ) : (
                  <>
                    <Tooltip title="Открыть настройки">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt="Fat Bat" src="/public/logo192.png" />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting: EPage) => (
                        <MenuItem
                          disableGutters
                          sx={menuItemSx}
                          key={setting}
                          onClick={handleCloseUserMenu}
                        >
                          <Typography
                            sx={{
                              ...menuItemTitleSx,
                              textDecoration: "none",
                              color: "black",
                            }}
                            component={Link}
                            to={mapEnumPageToPath[setting]}
                            textAlign="center"
                          >
                            {enumToMenuMapper[setting]}
                          </Typography>
                        </MenuItem>
                      ))}
                      <MenuItem
                        disableGutters
                        sx={menuItemSx}
                        key="exit"
                        onClick={handleCloseUserMenu}
                      >
                        <Typography
                          color="#666"
                          sx={{
                            ...menuItemTitleSx,
                            textTransform: "capitalize",
                          }}
                          onClick={logout}
                          component={Button}
                          textAlign="center"
                        >
                          Выйти
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Fade>
    </>
  );
};
export default Header;
