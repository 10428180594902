import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { wrapSX } from "./styles";
import { sortBy } from "lodash";

const cardSx = {
  boxShadow: "none",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "0",
  padding: "20px",
  border: "0.5px solid rgb(228, 229, 231)",
  transition: "box-shadow .5s ease",
  cursor: "pointer",
  ":hover": {
    boxShadow: "0 0 10px 0 #223c5033",
  },
};

const SubscribeBlock: FC<any> = ({ productList = [], displayPayment }) => {
  const { t } = useTranslation();

  return (
    <Container sx={wrapSX}>
      <Grid container spacing={2}>
        {sortBy(productList, "order").map((item: any) => (
          <Grid
            key={item?.id}
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ marginBottom: "60px" }}
          >
            <Card
              sx={[cardSx, { height: "100%" }]}
              onClick={displayPayment.bind(null, { items: [item] })}
            >
              <CardContent>
                <Typography marginBottom="15px" variant="h5" component="div">
                  {item?.name}
                </Typography>
                <Typography
                  sx={{ fontSize: 14, marginBottom: "10px" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {item?.description}
                </Typography>
              </CardContent>
              <CardActions
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button size="small">Подписаться</Button>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  variant="h5"
                >
                  <Tooltip title="После успешной оплаты профиль активируется автоматически">
                    <HelpOutlineIcon
                      onClick={(e) => e.stopPropagation()}
                      sx={{ mr: "5px", fontSize: "20px", color: "#6f6f6f" }}
                    />
                  </Tooltip>
                  <CurrencyRubleOutlinedIcon
                    sx={{ mr: "0", fontSize: "18px" }}
                  />
                  {item?.price}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SubscribeBlock;
