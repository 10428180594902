import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography, useMediaQuery } from "@mui/material";

import mainVideo from "../../../../Videos/main.mp4";
// {
//   .video-container iframe {
//     /* height = 100 * (9 / 16) = 56.25 */
//
//   }
// }

// @media  {
//   .video-container iframe {
//     /* width = 100 / (9 / 16) = 177.777777 */
//     ;
//   }
// }

const defaultVideoStyle: Record<string, string> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "100vw",
  height: "100vh",
  transform: "translate(-50%, -50%)",
  pointerEvents: "none",
};

const VideoBlock: FC = () => {
  const { t } = useTranslation();
  const isFixedFontSize = useMediaQuery("(min-width:560px)");
  const minMQ = useMediaQuery("(min-aspect-ratio: 16/9)");
  const maxMQ = useMediaQuery("(max-aspect-ratio: 16/9)");

  return (
    <Container
      disableGutters
      sx={{
        position: "relative",
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        sx={[
          {
            userSelect: "none",
            color: "white",
            fontSize: "21vw",
            textTransform: "uppercase",
            textShadow: "black 5px 5px 0px",
            position: "absolute",
            zIndex: 1,
          },
          isFixedFontSize && {
            fontSize: "140px",
          },
        ]}
      >
        {t("winterx")}
      </Typography>
      <video
        style={{
          ...defaultVideoStyle,
          ...(minMQ && { height: "56.25vw" }),
          ...(maxMQ && { width: "177.78vh" }),
        }}
        autoPlay
        muted
        loop
        id="video"
      >
        <source src={mainVideo} type="video/mp4" />
      </video>
    </Container>
  );
};

export default VideoBlock;
