import { combineReducers } from "@reduxjs/toolkit";

import productsReducer from "./Products";
import cartReducer from "./Cart";
import profileReducer from "./Profile";
import authReducer from "./Auth";

export const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  cart: cartReducer,
  products: productsReducer,
});
