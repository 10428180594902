import { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container/Container";
import { Button, Typography } from "@mui/material";

import { EPage } from "../Defenitions/enums/EPage";

import { mapEnumPageToPath } from "./mappers/mapEnumPageToPath";

import Header from "./Components/Header";

import Home from "./Home";
import Profile from "./Profile";
import Documents from "./Documents";
import Auth from "./Auth";
import Shop from "./Shop";
import Footer from "./Components/Footer";
import { AppModals } from "./Components/AppModals";

const appDefaultWrapSX = {
  display: "flex",
  flex: "1",
  flexDirection: "column",
};

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isAppBarDisplay, setIsAppBarDisplay] = useState(false);

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        overflowX: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
      onScroll={(e: any) =>
        e.target.scrollTop > 100
          ? !isAppBarDisplay && setIsAppBarDisplay(true)
          : isAppBarDisplay && setIsAppBarDisplay(false)
      }
    >
      <CssBaseline />
      <Header isDisplay={pathname !== "/" || isAppBarDisplay} />
      <Container
        sx={appDefaultWrapSX}
        disableGutters
        maxWidth={false}
        className="App"
      >
        <Container sx={{ flex: 1 }} disableGutters maxWidth={false}>
          <Routes>
            <Route path={mapEnumPageToPath[EPage.HOME]} element={<Home />} />
            <Route path={mapEnumPageToPath[EPage.AUTH]} element={<Auth />} />
            {/* <Route path={mapEnumPageToPath[EPage.SHOP]} element={<Shop />} /> */}
            <Route
              path={mapEnumPageToPath[EPage.PROFILE]}
              element={<Profile />}
            />
            <Route
              path={`${mapEnumPageToPath[EPage.DOCS]}/*`}
              element={<Documents />}
            />
            <Route
              path="*"
              element={
                <Container
                  sx={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography marginBottom="50px" variant="h4">
                    Страница не найдена
                  </Typography>
                  <Button onClick={() => navigate("/")}>На главную</Button>
                </Container>
              }
            />
          </Routes>
        </Container>
        <AppModals />
        <Footer />
      </Container>
    </Container>
  );
}

export default App;
