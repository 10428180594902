import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { isDev } from "../Utils";

import { useFetch } from "./useFetch";
import { resetAuth, setAuth } from "../Services/slices/Auth";
import { resetProfile, setProfile } from "../Services/slices/Profile";
import { LS_AUTH_TOKEN } from "../Constants";

const FIREBASE_CONFIG = isDev
  ? // ? {
    //     apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
    //     authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
    //     projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
    //     storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
    //     messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
    //     appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
    //   }
    {
      apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_PROD_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_PROD_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_PROD_MEASUREMENT_ID,
    }
  : {
      apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_PROD_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
      messagingSenderId:
        process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_PROD_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_PROD_MEASUREMENT_ID,
    };

const firebaseApp = initializeApp(FIREBASE_CONFIG);
const googleProvider = new GoogleAuthProvider();

export const useFirebase = () => {
  const dispatch = useDispatch();
  const { defaultHeaders, postRequest } = useFetch({
    url: "/api/profile/create",
  });
  const { getRequest: getProfile } = useFetch({
    url: "/api/profile",
  });
  const auth = useMemo(() => getAuth(), []);

  const authViaGoogle = useCallback(() => {
    signInWithPopup(auth, googleProvider)
      .then(async (userCredential) => {
        const { creationTime, lastSignInTime } = userCredential.user.metadata;
        const token = await userCredential.user.getIdToken();

        const authData = {
          email: userCredential.user.email,
        };

        dispatch(setAuth(authData));
        if (!!creationTime && creationTime === lastSignInTime) {
          postRequest(
            { email: userCredential.user.email },
            {
              headers: {
                ...defaultHeaders,
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }
        getProfile({
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${token}`,
          },
        }).then(({ data }: any) => dispatch(setProfile(data)));
      })
      .catch(console.warn);
  }, [auth, dispatch, postRequest, defaultHeaders, getProfile]);

  const logout = useCallback(() => {
    signOut(auth).then(() => {
      dispatch(resetAuth());
      dispatch(resetProfile());
    });
  }, [auth, dispatch]);

  return { authViaGoogle, logout };
};
