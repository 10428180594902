import { FC, useMemo } from "react";
import { Avatar, Container, IconButton, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { GoogleButton } from "../GoogleButton";

const authBlockSX = {
  width: "auto",
  borderRadius: "8px",
  padding: "24px 36px",
  boxShadow: "0 0 15px 5px #777",
  background: "white",
  color: "initial",
};

const Auth: FC<any> = ({
  onBack,
  googleButtonClick,
  account,
  onLogoutClick,
}) => {
  const AuthContent = useMemo(() => {
    const notAuthContent = <GoogleButton onClick={googleButtonClick} />;

    return account?.email ? (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Avatar
          sx={{
            marginRight: "15px",
          }}
          variant="circular"
        >
          {account.email?.[0]?.toUpperCase()}
        </Avatar>
        <div>{account.email}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            paddingTop: "20px",
          }}
        >
          <Button onClick={onLogoutClick}>Выйти</Button>
        </div>
      </div>
    ) : (
      notAuthContent
    );
  }, [googleButtonClick, onLogoutClick, account]);

  return (
    <Container sx={authBlockSX} maxWidth={false} disableGutters>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "15px",
        }}
      >
        {onBack ? (
          <IconButton onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <div style={{ width: "40px" }} />
        )}
        <div>Авторизация</div>
        <div style={{ width: "40px" }} />
      </div>
      <div
        style={{
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {AuthContent}
      </div>
    </Container>
  );
};

export default Auth;
