import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  Container,
  Fade,
  Modal,
  IconButton,
  Button,
  Tooltip,
  Chip,
  ListItem,
  Paper,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SendIcon from "@mui/icons-material/Send";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";

import mystery from "../../../../Images/products/mystery.png";

import { wrapSX } from "./styles";
import { sortBy } from "lodash";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { genImagePath } from "../../../../Utils";

const colorList = [
  "default",
  "primary",
  "secondary",
  "error",
  "info",
  "success",
  "warning",
];

const getRandomColor = (): any => {
  const min = Math.ceil(0);
  const max = Math.floor(colorList.length);
  const colorItem = Math.floor(Math.random() * (max - min + 1)) + min;

  return colorList[colorItem];
};

const productImage: any = {
  mystery,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 700,
  width: "100%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const ProductsBlock: FC<any> = ({ productList = [], addToCart }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<{ isDisplay: boolean; item: any }>({
    isDisplay: false,
    item: null,
  });

  const handleOpen = (item: any) => setModal({ isDisplay: true, item });
  const handleClose = () => setModal((prev) => ({ ...prev, isDisplay: false }));

  return (
    <Container sx={wrapSX}>
      <Grid container spacing={2}>
        {sortBy(productList, "order").map((item: any, key) => {
          return (
            <Grid
              key={item.id}
              xs={12}
              sm={productList % 2 > 0 && key === 0 ? 12 : 6}
              md={productList % 2 > 0 && key === 0 ? 12 : 6}
              lg={productList % 2 > 0 && key === 0 ? 12 : 6}
              sx={{ marginBottom: "60px" }}
            >
              <Card
                sx={{
                  boxShadow: "none",
                }}
              >
                <CardActionArea
                  sx={[
                    {
                      "& .MuiCardActionArea-focusHighlight": {
                        display: "none",
                      },
                      height: "100%",
                    },
                  ]}
                  onClick={handleOpen.bind(null, item)}
                >
                  <CardMedia
                    sx={{
                      backgroundSize: "contain",
                      objectFit: "contain",
                    }}
                    component="img"
                    height="280"
                    src={
                      genImagePath(item?.images?.[0]?.path) ||
                      productImage["mystery"]
                    }
                    onMouseOver={(event) => {
                      event.currentTarget.src =
                        genImagePath(item?.images?.[1]?.path) ||
                        genImagePath(item?.images?.[0]?.path) ||
                        productImage["mystery"];
                      event.currentTarget.alt = "Продукт: " + item?.name + " 1";
                    }}
                    onMouseOut={(event) => {
                      event.currentTarget.src =
                        genImagePath(item?.images?.[0]?.path) ||
                        productImage["mystery"];
                      event.currentTarget.alt = "Продукт: " + item?.name;
                    }}
                    alt={"Продукт: " + item?.name}
                  />
                  <CardContent
                    sx={{
                      background: "none",
                      boxShadow: "none",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      color={item?.price ? "#949494" : "text.primary"}
                      component="div"
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      variant="h5"
                    >
                      <CurrencyRubleOutlinedIcon
                        sx={{ mr: "0", fontSize: "18px" }}
                      />
                      {item?.price}
                    </Typography>
                    {/* <Typography variant="body2" color="text.secondary">
                      {modal.item?.description}
                    </Typography> */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.isDisplay}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modal.isDisplay}>
          <Box sx={style}>
            {!!modal.item?.tags?.length && (
              <Container
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  listStyle: "none",
                  justifyContent: "flex-start",
                  p: 0,
                  m: 0,
                  mb: "15px",
                }}
                disableGutters
                component="ul"
              >
                {modal.item.tags.map((tagItem: string) => {
                  return (
                    <ListItem
                      sx={{
                        flex: "0",
                        mr: "5px",
                        opacity: "0.8",
                      }}
                      disablePadding
                      disableGutters
                      key={tagItem}
                    >
                      <Chip
                        size="small"
                        label={tagItem}
                        color={getRandomColor()}
                      />
                    </ListItem>
                  );
                })}
              </Container>
            )}
            <img
              style={{
                width: "100%",
                maxHeight: "363px",
                height: "auto",
                objectFit: "cover",
              }}
              src={
                genImagePath(modal.item?.images?.[0]?.path) ||
                productImage["mystery"]
              }
              onMouseOver={(event) => {
                event.currentTarget.src =
                  genImagePath(modal.item?.images?.[1]?.path) ||
                  productImage["mystery"];
                event.currentTarget.alt = "Продукт: " + modal.item?.name + " 1";
              }}
              onMouseOut={(event) => {
                event.currentTarget.src =
                  genImagePath(modal.item?.images?.[0]?.path) ||
                  productImage["mystery"];
                event.currentTarget.alt = "Продукт: " + modal.item?.name;
              }}
              alt={"Продукт: " + modal.item?.name}
            />

            <Typography id="transition-modal-title" variant="h6" component="h2">
              {modal.item?.name}
            </Typography>
            <Typography id="transition-modal-description" variant="body2">
              {modal.item?.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: 2,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                onClick={addToCart.bind(null, modal?.item)}
              >
                <>
                  В корзину{"  "}
                  <CurrencyRubleOutlinedIcon
                    sx={{ ml: "5px", mr: "2px", fontSize: "18px" }}
                  />
                  {modal?.item?.price}
                </>
              </Button>
              {modal.item?.related ? (
                <Tooltip
                  arrow
                  title={`Устройство без которого работа невозможна`}
                >
                  <Button
                    startIcon={<PriorityHighIcon color="error" />}
                    onClick={handleOpen.bind(null, modal.item.related)}
                  >
                    <Typography color="error" fontSize="12px">
                      {modal.item?.related?.name}
                    </Typography>
                  </Button>
                </Tooltip>
              ) : null}
            </Box>
            {/* {productPrice?.[modal.item] && (
              <Box sx={{ display: "flex", mt: 2, alignItems: "center" }}>
                <Typography id="transition-modal-price">
                  {productPrice?.[modal.item] || ""}
                </Typography>
                <IconButton
                  sx={{
                    marginLeft: "10px",
                  }}
                  size="large"
                >
                  <ShoppingCartIcon fontSize="small" />
                </IconButton>
              </Box>
            )} */}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default ProductsBlock;
