import { FC } from "react";

import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import { wrapSX } from "./styles";
import { useTranslation } from "react-i18next";

const styleTitle = {
  mb: "45px",
  fontSize: "30px",
  lineHeight: "1.25",
  fontWeight: 600,
  textAlign: "center",
};
const styleCardItem = {
  boxShadow: "none",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const styleItem = { width: "100px", height: "auto", mb: "37px" };
const styleItemContent = { textAlign: "center" };
const styleItemTitle = {
  mb: "25px",
  fontSize: "16px",
  lineHeight: "1.35",
  fontWeight: 500,
};
const styleItemDesc = {
  fontSize: "12px",
  lineHeight: "1.55",
  fontWeight: 300,
};
const styleCardWrap = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const SolutionBlock: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container sx={wrapSX}>
      <Typography sx={styleTitle}>{t("our_solution")}</Typography>
      <Container
        sx={[
          styleCardWrap,
          isMD && {
            flexDirection: "initial",
            width: "initial",
            minWidth: "25%",
          },
        ]}
      >
        <Card sx={styleCardItem}>
          <CardMedia>
            <AutoAwesomeIcon sx={styleItem} />
          </CardMedia>
          <CardContent sx={styleItemContent}>
            <Typography sx={styleItemTitle}>{t("solution.unique")}</Typography>
            <Typography sx={styleItemDesc}>
              {t("solution.unique_desc")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={styleCardItem}>
          <CardMedia>
            <AltRouteIcon sx={styleItem} />
          </CardMedia>
          <CardContent sx={styleItemContent}>
            <Typography sx={styleItemTitle}>
              {t("solution.flexible")}
            </Typography>
            <Typography sx={styleItemDesc}>
              {t("solution.flexible_desc")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={styleCardItem}>
          <CardMedia>
            <SupportAgentIcon sx={styleItem} />
          </CardMedia>
          <CardContent sx={styleItemContent}>
            <Typography sx={styleItemTitle}>{t("solution.help")}</Typography>
            <Typography sx={styleItemDesc}>
              {t("solution.help_desc")}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Container>
  );
};

export default SolutionBlock;
