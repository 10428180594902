import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import { useFirebase } from "../../CustomHooks/useFirebase";
import Auth from "../../Components/Auth";

const authContainerSX = {
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const AuthPage: FC<any> = () => {
  const navigate = useNavigate();
  const { authViaGoogle } = useFirebase();
  const { authData } = useSelector((state: any) => {
    const authData = state.auth;

    return { authData };
  });

  return (
    <Container sx={authContainerSX}>
      <Auth
        onBack={() => navigate("/")}
        googleButtonClick={authViaGoogle}
        account={{ email: authData.email }}
      />
    </Container>
  );
};

export default AuthPage;
