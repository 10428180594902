import { FC } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import project from "../../../../Images/about_us.jpg";

const ProjectBlock: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Container disableGutters maxWidth={false}>
      <Card
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
          },
          isMD && {
            flexDirection: "row",
          },
        ]}
      >
        <CardMedia
          sx={[
            {
              order: 0,
            },
            isMD && {
              order: 1,
              maxWidth: "60%",
            },
          ]}
          component="img"
          height="auto"
          image={project}
          alt="Project"
        />
        <CardContent
          sx={[
            { padding: "10%", "&:last-child": { paddingBottom: "10%" } },
            isMD && { flexBasis: "50%" },
          ]}
        >
          <Typography
            gutterBottom
            variant="h4"
            fontWeight="bolder"
            component="div"
          >
            {t("about_project")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t("about_project_desc")}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ProjectBlock;
