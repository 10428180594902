import { FC, useCallback, useState } from "react";
import { AddShoppingCart } from "@mui/icons-material";
import {
  Drawer,
  Badge,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";

import ContactBlock from "./Components/ContactBolck";
import ProductsBlock from "./Components/ProductsBlock";
import SubscribeBlock from "./Components/SubscribeBlock";
import ApplicationBlock from "./Components/ApplicationBlock";
import ProjectBlock from "./Components/ProjectBolck";
import SloganBlock from "./Components/SloganBolck";
import SolutionBlock from "./Components/SolutionBolck";
import VideoBlock from "./Components/VideoBlock";
import { useDispatch, useSelector } from "react-redux";
import Cart from "../Components/Cart";
import { addToCart, deleteFromCart } from "../../Services/slices/Cart";
import { useProducts } from "../../CustomHooks/useProducts";
import { EProductType } from "../../Defenitions/enums/EProductType";
import { usePayment } from "../../CustomHooks/usePayment";
import { useFetch } from "../../CustomHooks/useFetch";

const Home: FC<any> = () => {
  const { postRequest } = useFetch({ url: "/api/calculate/price" });
  const dispatch = useDispatch();
  const [isPriceFetching, setIsPriceFetching] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [priceCart, setPriceCart] = useState(0);
  const { displayPayment, paymentIsCreating } = usePayment();
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up("sm"));

  const cartItems = useSelector((state: any) => state?.cart);

  const { productList } = useProducts();
  const profile = useSelector(({ profile }: any) => profile);

  const getTotalItems = (items: any[]) =>
    items?.reduce?.((acc, item) => acc + item.amount, 0);

  const handleAddToCart = (clickedItem: any) => {
    dispatch(addToCart(clickedItem));
  };

  const handleRemoveFromCart = (id: number) => {
    dispatch(deleteFromCart({ id } as any));
  };

  const debouncedPriceCheck = useCallback(
    debounce((body) => {
      setIsPriceFetching(true);
      postRequest(body)
        .then((response) => setPriceCart(response.data.price))
        .catch((error) => {
          console.error("Price check:", error);
        })
        .finally(setIsPriceFetching.bind(null, false));
    }, 500),
    [postRequest]
  );

  return (
    <>
      <VideoBlock />
      <SloganBlock />
      <ProductsBlock
        productList={productList.filter(
          ({ type }: any) => type === EProductType.SYSTEM
        )}
        addToCart={handleAddToCart}
      />
      <SubscribeBlock
        productList={productList.filter(
          ({ type }: any) => type === EProductType.SUBSCRIPTION
        )}
        displayPayment={displayPayment}
      />
      <ApplicationBlock />
      <ProjectBlock />
      <SolutionBlock />
      <ContactBlock />

      <Drawer
        PaperProps={{
          sx: [
            { width: "100%" },
            isSM && {
              width: "65%",
            },
          ],
        }}
        anchor="right"
        open={cartOpen}
        onClose={() => setCartOpen(false)}
      >
        <Cart
          cartItems={cartItems}
          addToCart={handleAddToCart}
          removeFromCart={handleRemoveFromCart}
          onSubmit={(promocode: any, meta: any) => {
            displayPayment({ items: cartItems, promocode, meta });
          }}
          onPriceCheck={debouncedPriceCheck}
          priceCart={priceCart}
          isPriceFetching={isPriceFetching}
          paymentIsCreating={paymentIsCreating}
          onClose={() => setCartOpen(false)}
          profile={profile}
        />
      </Drawer>

      <IconButton
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "75px",
          height: "75px",
          background: "#fafafa80",
        }}
        onClick={() => setCartOpen(true)}
      >
        <Badge badgeContent={getTotalItems(cartItems)} color="error">
          <AddShoppingCart />
        </Badge>
      </IconButton>
    </>
  );
};

export default Home;
