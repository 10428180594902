import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";
import CloseIcon from "@mui/icons-material/Close";

import CartItem from "../CartItem";
import { find } from "lodash";
import { EProductType } from "../../../Defenitions/enums/EProductType";
import { RequiredForSystemBlock } from "./RequiredForSystemBlock";
import { EMetaFields } from "./enums";

type Props = {
  cartItems: any[];
  addToCart: (clickedItem: any) => void;
  removeFromCart: (id: number) => void;
  onSubmit: (promocode: any, meta: any) => void;
  onPriceCheck: (body: { items: any[]; promocode: any }) => void;
  promocodeError?: string;
  promocodeInfo?: string;
  priceCart?: number;
  isPriceFetching?: boolean;
  paymentIsCreating?: boolean;
  onClose: Function;
  profile: any;
};

const REQUIRED_META_FIELDS = [EMetaFields.cdek];
const Cart = ({
  cartItems,
  addToCart,
  removeFromCart,
  onSubmit,
  promocodeError,
  promocodeInfo,
  onPriceCheck,
  priceCart,
  isPriceFetching,
  paymentIsCreating,
  onClose,
  profile,
}: Props) => {
  const [promocode, setPromocode] = useState("");
  const [meta, setMeta] = useState<any>({});
  const [metaErrors, setMetaErrors] = useState<any>({});
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  const isSM = useMediaQuery(theme.breakpoints.down("md"));

  const hasSystemInCart = useMemo(() => {
    return !!find(cartItems, { type: EProductType.SYSTEM });
  }, [cartItems]);

  const checkMeta = useCallback(() => {
    if (!hasSystemInCart) return true;

    const returnedErrors: any = {};
    let returnedFlag = true;

    [
      ...REQUIRED_META_FIELDS,
      EMetaFields.contact,
      // ...(meta.isAnonymous
      //   ? [EMetaFields.contact]
      //   : [EMetaFields.fullName, EMetaFields.phone]),
    ].forEach((metaField) => {
      if (!meta?.[metaField]) {
        returnedErrors[metaField] = "Поле обязательно для заполнения";
        returnedFlag = false;
      }
    });
    setMetaErrors(returnedErrors);

    return returnedFlag;
  }, [hasSystemInCart, meta]);

  useEffect(() => {
    onPriceCheck({ items: cartItems, promocode });
  }, [promocode, cartItems, onPriceCheck]);
  useEffect(() => {
    if (profile?.email)
      setMeta((prev: any) => ({ ...prev, contact: profile.email }));
  }, [profile?.email]);

  return (
    <>
      {isMD && (
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1,
          }}
          onClick={onClose as any}
        >
          <CloseIcon sx={{ width: "32px", height: "32px" }} />
        </IconButton>
      )}
      <form
        noValidate
        autoComplete="off"
        style={{ padding: "20px" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (checkMeta()) onSubmit(promocode, meta);
        }}
      >
        <FormControl fullWidth>
          <h2>Ваша корзина</h2>
          <div style={{ marginBottom: "25px" }}>
            {cartItems.length === 0 ? <p>Корзина пуста.</p> : null}
            {cartItems.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                addToCart={addToCart}
                removeFromCart={removeFromCart}
              />
            ))}
          </div>
          {/* <div style={{ marginBottom: "25px" }}>
          <TextField
            sx={{ width: "100%" }}
            value={promocode}
            onChange={(e) => setPromocode(e.target.value)}
            placeholder="Промокод"
            error={!!promocodeError}
            size="small"
          />
          {promocodeError && <p>{promocodeError}</p>}
          {promocodeInfo && <p>{promocodeInfo}</p>}
        </div> */}
          {hasSystemInCart && (
            <RequiredForSystemBlock
              meta={meta}
              metaErrors={metaErrors}
              setField={(field: any, value: any) =>
                setMeta((prev: any) => ({ ...prev, [field]: value }))
              }
            />
          )}
          <div style={{ display: "flex", flex: 1, marginBottom: "25px" }}>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              fullWidth
              disabled={paymentIsCreating}
              startIcon={
                paymentIsCreating ? (
                  <CircularProgress
                    sx={{ maxWidth: "16px", maxHeight: "16px" }}
                  />
                ) : null
              }
            >
              <Typography marginRight="15px">К оплате</Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isPriceFetching ? (
                  <CircularProgress
                    sx={{ maxWidth: "32px", maxHeight: "32px" }}
                  />
                ) : (
                  <>
                    <CurrencyRubleOutlinedIcon fontSize="small" />
                    <Typography variant="h6">{priceCart}</Typography>
                  </>
                )}
              </div>
            </Button>
          </div>
          {isSM && (
            <div style={{ display: "flex", flex: 1 }}>
              <Button
                color="inherit"
                variant="outlined"
                size="large"
                fullWidth
                onClick={onClose as any}
              >
                <Typography marginRight="15px">Закрыть</Typography>
              </Button>
            </div>
          )}
        </FormControl>
      </form>
    </>
  );
};

export default Cart;
