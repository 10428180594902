export const paymentStatusToTitleMapper: any = {
  succeeded: "Оплачен",
  pending: "В обработке",
  waiting_for_capture: "Ожидает подтверждения",
  canceled: "Отменен",
  delivery: "Доставка",
  done: "Выполнен",
};

export const paymentStatusToColorMapper: any = {
  succeeded: "green",
  pending: "gray",
  waiting_for_capture: "gray",
  canceled: "red",
  delivery: "blue",
  done: "green",
};
