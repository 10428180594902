import { useCallback, useEffect, useMemo, useState } from "react";
import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import find from "lodash/find";

import { defaultOrigin } from "../Utils";
import { EServerErrors } from "../Defenitions/enums/EServerErrors";
import { LS_AUTH_TOKEN } from "../Constants";

const getUserToken = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        console.log("User not logged in");
        resolve(null);
      }
      unsub();
    });
  });
};

export const useFetch = ({
  origin = defaultOrigin,
  url = "",
  withoutAuth,
}: {
  url?: string;
  origin?: string;
  withoutAuth?: boolean;
}) => {
  const [token, setToken] = useState("");

  const authorizationHeader = useMemo(
    () => (withoutAuth ? { Authorization: null } : {}),
    [withoutAuth]
  );
  const defaultHeaders = useMemo(
    () => ({
      ...authorizationHeader,
      Accept: "application/json; charset=UTF-8",
      "Accept-Language": "ru-RU",
      "Content-Type": "application/json; charset=UTF-8",
    }),
    [authorizationHeader]
  );
  const fullUrl = useMemo(() => `${origin}${url}`, [origin, url]);

  const getRequest = useCallback(
    (params: Record<string, any> = {}) => {
      const { additionalUrl = "", ...axiosParams } = params;

      return axios.get(`${fullUrl}${additionalUrl}`, {
        headers: defaultHeaders,
        ...axiosParams,
      });
    },
    [fullUrl, defaultHeaders]
  );
  const putRequest = useCallback(() => {}, []);
  const postRequest = useCallback(
    async (data?: any, params: Record<string, any> = {}) => {
      const { additionalUrl = "", ...axiosParams } = params;

      return axios.post(`${fullUrl}${additionalUrl}`, data, {
        headers: defaultHeaders,
        ...axiosParams,
      });
    },
    [fullUrl, defaultHeaders]
  );
  const deleteRequest = useCallback(() => {}, []);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        let needRefreshToken = false;
        const originalRequest = error.config;

        if (
          !!find(error?.response?.data?.errors || [], {
            code: EServerErrors.TOKEN_EXPIRED,
          })
        ) {
          needRefreshToken = true;
        }

        if (needRefreshToken) {
          const refreshedToken = await getUserToken();

          if (!refreshedToken) {
            return Promise.reject(error);
          }

          setToken(refreshedToken);
          localStorage.setItem(LS_AUTH_TOKEN, refreshedToken);

          originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;

          return axios.request(originalRequest);
          // .then((response) => {
          //   Promise.resolve(response);
          // })
          // .catch((err) => {
          //   Promise.reject(err);
          // });
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    const tokenFromStorage = localStorage.getItem(LS_AUTH_TOKEN);

    if (tokenFromStorage) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${tokenFromStorage}`;
      setToken(tokenFromStorage);
    } else
      (async () =>
        await getAuth()
          .currentUser?.getIdToken()
          .then((token) => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            setToken(token);
            localStorage.setItem(LS_AUTH_TOKEN, token);
          }))();
  }, []);

  return {
    token,
    defaultHeaders,
    getRequest,
    putRequest,
    postRequest,
    deleteRequest,
  };
};
