import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { EAppModal } from "../Defenitions/enums/EAppModal";

export const useAppModal = () => {
  const [modalList, setModalList] = useState<any>([]);
  const [modalDict, setModalDict] = useState<any>({});
  const [searchParams, setSearchParam] = useSearchParams();

  const setAppModal = useCallback<any>(
    (route: EAppModal, value = "true") => {
      searchParams.set(route, value);
      setSearchParam(searchParams);
    },
    [searchParams, setSearchParam]
  );
  const removeAppModal = useCallback(
    (route: EAppModal) => {
      searchParams.delete(route);
      setSearchParam(searchParams);
    },
    [searchParams, setSearchParam]
  );

  useEffect(() => {
    const currentModalList: string[] = [];
    const currentModalDict: any = [];
    searchParams.forEach((value, key) => {
      currentModalList.push(key);
      currentModalDict[key] = value;
    });

    setModalList(currentModalList);
    setModalDict(currentModalDict);
  }, [searchParams]);

  return {
    currentModals: modalList,
    modalDict,
    setAppModal,
    removeAppModal,
  };
};
