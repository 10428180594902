import { Backdrop } from "@mui/material";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import Auth from "../../../Components/Auth";
import { useFirebase } from "../../../CustomHooks/useFirebase";
import { useAppModal } from "../../../CustomHooks/useAppModal";
import { EAppModal } from "../../../Defenitions/enums/EAppModal";

export const AppModals: FC<any> = () => {
  const { currentModals, removeAppModal } = useAppModal();
  const { authViaGoogle } = useFirebase();
  const { authData } = useSelector((state: any) => {
    const authData = state.auth;

    return { authData };
  });

  const modals = useMemo(() => {
    const returnedModals = [];

    switch (true) {
      case currentModals.includes(EAppModal.AUTH): {
        returnedModals.push(
          <Backdrop
            key={EAppModal.AUTH}
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
            onClick={removeAppModal.bind(null, EAppModal.AUTH)}
          >
            <Auth
              googleButtonClick={authViaGoogle}
              account={{ email: authData.email }}
            />
          </Backdrop>
        );
        break;
      }

      default:
        break;
    }

    return returnedModals;
  }, [currentModals]);

  return modals.length ? <>{modals}</> : null;
};
