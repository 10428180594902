import { IconButton, Typography } from "@mui/material";

import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CurrencyRubleOutlinedIcon from "@mui/icons-material/CurrencyRubleOutlined";
import { genImagePath } from "../../../Utils";

type Props = {
  item: any;
  addToCart: (clickedItem: any) => void;
  removeFromCart: (id: number) => void;
};

const CartItem = ({ item, addToCart, removeFromCart }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid lightblue",
        padding: "20px 0",
      }}
    >
      <img
        style={{
          maxWidth: "80px",
          objectFit: "cover",
          marginLeft: "40px",
        }}
        src={genImagePath(item.images?.[0]?.path)}
        alt={item.name}
      />
      <div style={{ width: "150px" }}>
        <h3>{item.name}</h3>
      </div>
      <div style={{ marginRight: "15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <IconButton size="small" onClick={() => addToCart(item)}>
              <ArrowDropUpOutlinedIcon />
            </IconButton>
            <IconButton size="small" onClick={() => removeFromCart(item.id)}>
              <ArrowDropDownOutlinedIcon />
            </IconButton>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography marginRight="5px">{item.amount}</Typography>
            <Typography>шт.</Typography>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CurrencyRubleOutlinedIcon fontSize="small" />
        <p>{(item.amount * item.price).toFixed(2)}</p>
      </div>
    </div>
  );
};

export default CartItem;
