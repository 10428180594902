import { Link, useMatch } from "react-router-dom";
import { Typography, List, ListItem, ListItemText, Paper } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { mapEnumPageToPath } from "../mappers/mapEnumPageToPath";
import { EPage } from "../../Defenitions/enums/EPage";
import gen_privacy from "../../gen/docs/gen_privacy_policy.json";
import gen_cookies from "../../gen/docs/gen_cookie_policy.json";
import gen_terms from "../../gen/docs/gen_site_terms.json";
import gen_app_license from "../../gen/docs/gen_app_license.json";

// IMPORTANT CHECK ALL DOCUMENTS IF ANY CHANGES
const docs = [
  {
    id: "privacy",
    title: "Политика конфиденциальности",
    content: gen_privacy,
  },
  {
    id: "cookies",
    title: "Политика использования cookies",
    content: gen_cookies,
  },
  {
    id: "terms",
    title: "Пользовательское соглашение",
    content: gen_terms,
  },
  {
    id: "app_license",
    title: "Лицензия на использование приложения",
    content: gen_app_license,
  },
];

// DocList component to display the list of docs
const DocList = () => (
  <Paper elevation={3} style={{ padding: "16px", margin: "16px" }}>
    <List>
      {docs.map((doc) => (
        <ListItem
          key={doc.id}
          sx={{ ":hover": { background: "#f9f9f9" } }}
          component={Link}
          to={`${mapEnumPageToPath[EPage.DOCS]}/${doc.id}`}
        >
          <ListItemText primary={doc.title} />
        </ListItem>
      ))}
    </List>
  </Paper>
);

// DocDetail component to display the content of a specific doc
const DocDetail = ({ docId }: any) => {
  const doc = docs.find((l) => l.id === docId);

  if (!doc) {
    return <Typography variant="h6">Документ не найден</Typography>;
  }

  return (
    <Paper elevation={3} style={{ padding: "16px", margin: "16px" }}>
      <ReactMarkdown>{doc.content}</ReactMarkdown>
    </Paper>
  );
};

// DocsDocs component containing the routing logic
const Documents = () => {
  const { params } = useMatch("/docs/:docId") || {};

  return params?.docId ? (
    <DocDetail docId={params?.docId} />
  ) : (
    <div>
      <Typography marginTop="32px" marginLeft="16px" variant="h4">
        Документы
      </Typography>
      <DocList />
    </div>
  );
};

export default Documents;
