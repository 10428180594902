import { FC } from "react";
import { Button, Container, Link, Typography } from "@mui/material";
import { format } from "date-fns";
import {
  paymentStatusToColorMapper,
  paymentStatusToTitleMapper,
} from "../../Mappers/paymentStatusMappers";

const paymentBlockSX = {
  width: "auto",
  borderRadius: "8px",
  padding: "24px 36px",
  paddingBottom: "46px",
  boxShadow: "0 0 15px 5px #777",
  background: "white",
  color: "initial",
  minWidth: "300px",
  display: "flex",
  flexDirection: "column",
};

const PaymentModal: FC<any> = ({ payment }) => {
  if (!payment) return null;

  const paymentStatus = payment.status;

  return (
    <Container
      onClick={(e) => e.stopPropagation()}
      sx={paymentBlockSX}
      maxWidth={false}
      disableGutters
    >
      <div
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ marginRight: "15px" }} variant="h5">
          Заказ {payment.orderNumber}
        </Typography>
        <Typography
          fontSize="bolder"
          color={paymentStatusToColorMapper[paymentStatus]}
        >
          {paymentStatusToTitleMapper[paymentStatus]}
        </Typography>
      </div>
      <div style={{ marginBottom: "25px" }}>
        Дата покупки: {format(payment.createdAt, "dd.MM.yyyy")}
      </div>
      <div style={{ marginBottom: "15px" }}>
        Товары:
        {payment.products.map((product: any) => {
          return (
            <div key={product.item.id} style={{ display: "flex" }}>
              <Typography>{product.amount}x&nbsp;</Typography>
              <Typography>{product.item?.name}</Typography>
            </div>
          );
        })}
      </div>

      <Button
        sx={{ borderRadius: "16px" }}
        variant="outlined"
        LinkComponent={Link}
        href={`mailto:winterx.team@yandex.ru?subject=[ВОПРОС]ЗАКАЗ_${payment.orderNumber}`}
      >
        Задать вопрос
      </Button>
    </Container>
  );
};

export default PaymentModal;
