import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { useFetch } from "../../CustomHooks/useFetch";
import { setProfile } from "../../Services/slices/Profile";
import { useAppModal } from "../../CustomHooks/useAppModal";
import { EAppModal } from "../../Defenitions/enums/EAppModal";
import { PaymentsTable } from "./Components/PaymentsTable";
import PaymentModal from "../../Components/PaymentModal";

const Profile: FC<any> = () => {
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState([]);
  const [currentPaymentIsLoading, setCurrentPaymentIsLoading] =
    useState<any>(false);
  const [currentPayment, setCurrentPayment] = useState<any>(null);
  const { getRequest: getProfile, token } = useFetch({
    url: "/api/profile",
  });
  const { getRequest: getPayments } = useFetch({
    url: "/api/payments",
  });
  const { setAppModal } = useAppModal();
  const profile = useSelector(({ profile }: any) => profile);

  const openPaymentModal = useCallback(
    (paymentId: any) => {
      if (!paymentId) {
        return;
      }
      if (!profile?.id) {
        setAppModal(EAppModal.AUTH);
        return;
      }

      if (!currentPayment) {
        setCurrentPaymentIsLoading(true);
        getPayments({
          additionalUrl: `/${paymentId}`,
          params: { profileId: profile?.id },
        })
          .then(({ data }) => setCurrentPayment(data?.[0]))
          .finally(setCurrentPaymentIsLoading.bind(null, false));
      }
    },
    [currentPayment, getPayments, profile?.id, setAppModal]
  );
  const closePaymentModal = useCallback(() => {
    setCurrentPayment(null);
  }, []);

  useEffect(() => {
    if (token)
      getProfile().then(({ data = [] }) => {
        dispatch(setProfile(data));
      });
  }, [token, getProfile]);

  useEffect(() => {
    if (!token || !profile?.id) return;

    getPayments({ params: { profileId: profile.id } }).then(({ data }) =>
      setPaymentList(data)
    );
  }, [token, profile.id]);

  return (
    <>
      {!profile?.id ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography
              variant="h6"
              display="block"
              width="100%"
              textAlign="center"
              marginBottom="25px"
            >
              Необходима авторизация
            </Typography>
            <Button
              color="inherit"
              variant="outlined"
              onClick={setAppModal.bind(null, EAppModal.AUTH)}
            >
              Войти
            </Button>
          </div>
        </div>
      ) : (
        <Container
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography marginTop="25px" marginBottom="25px" variant="h4">
            Профиль
          </Typography>
          <Card
            sx={{
              width: "100%",
              minHeight: "50%",
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Avatar sx={{ marginRight: "15px" }}>
                {profile.email?.[0]?.toUpperCase()}
              </Avatar>
              <Typography>email:&nbsp;</Typography>
              <Typography>{profile.email}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography>Аккаунт активирован:&nbsp;</Typography>
              <Checkbox checked={profile.isActiveAccount} disabled />
            </div>
            {profile.isActiveAccount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Typography>Активирован до:&nbsp;</Typography>
                <Typography>{profile.activeTo}</Typography>
              </div>
            )}

            <div>
              <PaymentsTable
                data={paymentList}
                onItemClick={openPaymentModal}
              />
            </div>
          </Card>
        </Container>
      )}
      <Backdrop
        key={EAppModal.PAYMENT}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={currentPaymentIsLoading || !!currentPayment}
        onClick={closePaymentModal}
      >
        {currentPaymentIsLoading ? (
          <CircularProgress />
        ) : (
          <PaymentModal payment={currentPayment} />
        )}
      </Backdrop>
    </>
  );
};

export default Profile;
