import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@mui/material";
import MovingIcon from "@mui/icons-material/Moving";

import { wrapSX } from "./styles";

const SloganBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <Container sx={wrapSX}>
      <div
        style={{
          marginBottom: "100px",
          border: "6px solid rgba(0, 0, 0, 0.87)",
          borderRadius: "10px",
        }}
      >
        <MovingIcon sx={{ display: "block", fontSize: "80px" }} />
      </div>
      <Typography
        sx={{
          marginBottom: "40px",
          color: "#707070",
          fontWeight: 300,
          fontSize: "48px",
          textAlign: "center",
        }}
      >
        {t("slogan")}
      </Typography>
    </Container>
  );
};

export default SloganBlock;
