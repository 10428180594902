import { FC, useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  Button,
  Modal,
  Fade,
  Box,
  Backdrop,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import appTutorMdGif from "../../../../Images/app_tutor_md.gif";
import ReactPlayer from "react-player";

const TUTORIAL_LINK = "https://youtu.be/X2zLRjPQfO4";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 704,
  width: "100%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const ApplicationBlock: FC = () => {
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.up("md"));
  const [isTutorDisplay, setIsTutorDisplay] = useState(false);

  const handleOpen = () => setIsTutorDisplay(true);
  const handleClose = () => setIsTutorDisplay(false);

  return (
    <Container sx={{ marginBottom: "180px" }} disableGutters maxWidth={false}>
      <Card
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            boxShadow: "none",
          },
          isMD && {
            flexDirection: "row",
          },
        ]}
      >
        <CardMedia
          sx={[
            isMD && {
              maxWidth: "60%",
            },
          ]}
          component="img"
          height="auto"
          image={appTutorMdGif}
          alt="Application"
        />
        <CardContent
          sx={[
            {
              padding: "10%",
              display: "flex",
              alignItems: "center",
              textTransform: "initial",
              justifyContent: "center",
              flexDirection: "column",
              "&:last-child": { paddingBottom: "10%" },
            },
            isMD && { flexBasis: "50%" },
          ]}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "initial",
              justifyContent: "center",
              mb: "15px",
            }}
            href={`/downloads/winterx.exe`}
            download
            target="_self"
            startIcon={<DownloadIcon sx={{ width: "32px", height: "auto" }} />}
            variant="contained"
          >
            <Typography
              gutterBottom
              variant="h6"
              marginBottom={0}
              whiteSpace="nowrap"
            >
              Скачать Winterx.exe
            </Typography>
          </Button>
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "initial",
              justifyContent: "center",
            }}
            size="small"
            onClick={handleOpen}
            variant="text"
          >
            <Typography gutterBottom marginBottom={0} whiteSpace="nowrap">
              Как настроить?
            </Typography>
          </Button>
        </CardContent>
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isTutorDisplay}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isTutorDisplay}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Настройка приложения
            </Typography>
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
              }}
            >
              <ReactPlayer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                url={TUTORIAL_LINK}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default ApplicationBlock;
