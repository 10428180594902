import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { format } from "date-fns";
import { Link, Typography } from "@mui/material";
import {
  paymentStatusToColorMapper,
  paymentStatusToTitleMapper,
} from "../../../../Mappers/paymentStatusMappers";

export const PaymentsTable = ({ data, onItemClick }: any) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Дата покупки</TableCell>
            <TableCell align="right">Товары</TableCell>
            <TableCell align="right">Сумма</TableCell>
            <TableCell align="right">Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((payment: any) => (
            <TableRow
              key={payment.createdAt}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link onClick={onItemClick.bind(null, payment.id)}>
                  {format(payment.createdAt, "dd-MM-yyyy")}
                </Link>
              </TableCell>
              <TableCell sx={{ whiteSpace: "pre" }} align="right">
                {payment?.products
                  ?.map(
                    (product: any) =>
                      `${product?.amount}x ${product.item?.name}`
                  )
                  .join(";\r\n")}
              </TableCell>
              <TableCell align="right">{payment?.price}</TableCell>
              <TableCell align="right">
                <Typography
                  fontSize="13px"
                  color={paymentStatusToColorMapper[payment.status]}
                >
                  {paymentStatusToTitleMapper[payment.status]}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
