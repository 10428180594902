import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useAppModal } from "./useAppModal";

import { useFetch } from "./useFetch";

export const usePayment = () => {
  const { setAppModal } = useAppModal();

  const [paymentIsCreating, setPaymentIsCreating] = useState(false);
  const { postRequest } = useFetch({
    url: "/api/payments/create",
  });

  const profileEmail = useSelector(({ auth }: any) => auth)?.email;

  const displayPayment = useCallback(
    async (productInfo: any) => {
      try {
        if (!profileEmail) return setAppModal;

        setPaymentIsCreating(true);
        const paymentCredential = await postRequest({
          ...productInfo,
          redirectTo: `${window.location.origin}/profile?redirect_from=payment`,
        });

        if (paymentCredential?.data) {
          window.open(paymentCredential.data, "_self");
        }
      } finally {
        setPaymentIsCreating(false);
      }
    },
    [postRequest]
  );
  return { displayPayment, paymentIsCreating };
};
